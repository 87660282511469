import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import eyeIcon from "../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
const employeesData = [
  {
    empId: "ET-001",
    name: "Amad Khan",
    currentPost: "General Manager",
    leaveType: "Casual Leave",
    from: "10 Aug 2024",
    to: "12 Aug 2024",
    reason: "Personal errand",
  },
  {
    empId: "ET-002",
    name: "Nimra Khan",
    currentPost: "Assistant",
    leaveType: "Sick Leave",
    from: "15 Aug 2024",
    to: "18 Aug 2024",
    reason: "Feeling not well",
  },
  {
    empId: "ET-003",
    name: "Basit Ali",
    currentPost: "System Analyst",
    leaveType: "Medical Leave",
    from: "20 Aug 2024",
    to: "22 Aug 2024",
    reason: "Minor Surgery",
  },
];

export default function LeaveScreen() {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [leaveRequestList, setLeaveRequestList] = useState();


  const GetLeaveRequest = async () => {
    const token = localStorage.getItem('authToken')
    try {
      const response = await fetch(` ${config.baseUrl}api/Leaves/GetLeaveRequests`, {
        method: 'Get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      const data =await response.json();
      setLeaveRequestList(data)
    }
    catch (error) {

    }

  };
  useEffect(() => {
    GetLeaveRequest()
  }, [])
  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => {
            navigate(`/leave/details/${rowData.empId}`, {
              state: { employee: rowData },
            });
          }}
          aria-label="view"
        >
          <img src={eyeIcon} alt="Eye" />
        </Button>
        <Button
          className=" p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
      </div>
    );
  };

  const handleDetailsClick = (rowData) => {
    navigate(`/leave/details/${rowData.empId}`, {
      state: { employee: rowData },
    });
  };
  const rowClassName = "p-datatable-clickable";

  const columnsData = [
    {
      field: "empId",
      header: "Emp ID",
    },
    {
      field: "name",
      header: "Name",
    },
    {
      field: "currentPost",
      header: "Current Post",
    },
    {
      field: "leaveType",
      header: "Leave Type",
    },
    {
      field: "from",
      header: "From",
    },
    {
      field: "to",
      header: "To",
    },
    {
      field: "reason",
      header: "Reason",
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>

    
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Leave" : "Add Leave"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm onHide={onHide} formData={isEdit ? selectedData : null} />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Leave Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Calendar
            id="date"
            placeholder="Calender"
            showIcon
            className="ml-3 "
          />
          <Button
            label="Filter"
            icon="pi pi-sliders-h"
            className="p-button ml-3 filters-btn"
          />
          <Button
            label="Add Leave"
            icon="pi pi-plus"
            onClick={() => toggleDialogMode(false)}
            className="p-button ml-3"
          />
        </div>
      </div>

      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={employeesData}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
          onRowClick={(event) => handleDetailsClick(event.data)}
          rowClassName={rowClassName}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
